/* src/styles/fonts.css */
@font-face {
  font-family: "GilroyReg";
  src: url("../assets/fonts/Gilroy-Regular.woff2") format("woff2"),
    url("../assets/fonts/Gilroy-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GilroyBold";
  src: url("../assets/fonts/Gilroy-Bold.woff2") format("woff2"),
    url("../assets/fonts/Gilroy-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "GilroyExtrabold";
  src: url("../assets/fonts/Gilroy-Extrabold.woff2") format("woff2"),
    url("../assets/fonts/Gilroy-Extrabold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "GilroyLight";
  src: url("../assets/fonts/Gilroy-Light.woff2") format("woff2"),
    url("../assets/fonts/Gilroy-Light.woff") format("woff");
  font-weight: light;
  font-style: normal;
}

@font-face {
  font-family: "GilroyMedium";
  src: url("../assets/fonts/Gilroy-Medium.woff2") format("woff2"),
    url("../assets/fonts/Gilroy-Medium.woff") format("woff");
  font-weight: medium;
  font-style: normal;
}
